import React, { Component }  from 'react';
import {Learn} from './Learn';
import {Verbs} from './Verbs';
import {Quizz} from './Quizz';
import {User} from './User';
import conjugate from './verbs.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { MD5 } from './md5';


export class Main extends Component {
    constructor(props) {
        super(props);
        //console.log('admin props', );

        let profile = {
            lang: 'no',
            name: '',
            levels: null
        };

        
        let savedProfile = localStorage.getItem("profile");
        if(savedProfile !== null){
            let sign = localStorage.getItem("sign");
            if(sign !== null){
                if (sign === MD5(savedProfile + "889fds.-sRt7")){
                    profile = JSON.parse(savedProfile);
                }
                else{
                    console.log("Profile sign not valid");
                }
            }
        }

        this.state = {
            profile : profile,
            currentView : '',
            previousView : '',
            selectedVerb: '',

            quizzCurrentLevel : 0
        };

        
        this.gotoView = this.gotoView.bind(this);
        this.gotoVerb = this.gotoVerb.bind(this);
        this.closeView = this.closeView.bind(this);
        this.updateProfile = this.updateProfile.bind(this);
        this.startQuizz = this.startQuizz.bind(this);
    }

    
    componentDidMount() {
        //document.title = 'Admin';
    }

    
    componentDidUpdate() {
        //if (this.props.util.labels === null && this.props.util.isLoading === false && this.props.user.currentLanguageId !== null) {
        //    this.props.requestLabels(this.props.user.currentLanguageId);
        //}
    }

   
    updateProfile(newProfile){
        this.setState({
            profile: newProfile
        });

        // Save profile to LocalStorage
        const profile = JSON.stringify(newProfile);
        const sign = MD5(profile + "889fds.-sRt7");
        localStorage.setItem("profile", profile);
        localStorage.setItem("sign", sign);

        
    }

    startQuizz(level){
        console.log("startQuizz level" , level);
        let nextLevel=0;
        if(level === null){
            // check last completed level
            if(this.state.profile.levels !== null){
                nextLevel = this.state.profile.levels.length;
            }
        }
        else{
            nextLevel = level;
        }

        console.log("next level" , nextLevel);

        this.setState({
            quizzCurrentLevel: nextLevel,
            currentView: 'Quizz'
        });


    }


    gotoView(viewName){
        this.setState({
            currentView: viewName
        });
    }

    closeView(){
        this.setState({
            currentView: this.state.previousView,
            previousView: ''
        });
    }

    gotoVerb(verbName, previousView){
        console.log("gotoVerb " ,verbName);
        console.log("previousView " ,previousView);
        this.setState({
            currentView: 'Learn',
            previousView: previousView,
            selectedVerb: verbName
        });

    }


    play1(){
        let audio = new Audio("https://spansk.net/audio/correct.mp3"); 
        audio.play();
    }

    play2(){
        let audio = new Audio("https://spansk.net/audio/wrong.mp3"); 
        audio.play();
    }

    play3(){
        let audio = new Audio("https://spansk.net/audio/level-complete.mp3"); 
        audio.play();
    }


    render() {
          // init 
        
          const user = <FontAwesomeIcon icon={faUser} /> 

          if(this.state.currentView === '')
            return (
                <div>
                    <div onClick={() => this.gotoView('User')} style={{zIndex:"1000", opacity:"50%", fontSize:"200%", position:"sticky",top : "0px" , textAlign:"left" , paddingLeft:"12px"}}>{user}</div>
                    <br/>
                    <h1 style={{fontFamily:"Lalezar", letterSpacing:"4px" , fontSize: "3em", color: "#dad2d8"}}>spansk.net</h1>
                    <br/>
                    <div style={{width:"40%", marginLeft:"30%"}}>
                        <img style={{width:"100%" , maxWidth:"400px"}} src="/img/trophy.svg" alt="Quizz"  />
                    </div>
                    
                    <br style={{clear: "both"}} />
                    <br/>
                    <div onClick={() => this.startQuizz(null)} style={{backgroundColor: "#07a0c3", color:"white", fontWeight: "bold", marginLeft: "20%", width:"60%", borderRadius: "8px", fontSize: "120%", padding:"8px" }} >
                        {this.state.profile.lang === 'en' ? 'Go to Quizz' : 'Gå til Quizz'}
                    </div>
                    <br style={{clear: "both"}} />
                    <br/>
                    <span onClick={() => this.gotoView('Verbs')} style={{textDecoration:"underline", color:"#fff"}} >{this.state.profile.lang === 'en' ? "Or learn verbs" : 'Eller lær verb'}</span> 
                    




                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    
                 
                </div>
            );



        return (
            <div>
                { this.state.currentView === 'Verbs' ? <Verbs verbs={conjugate.verbs} profile={this.state.profile} onClose={this.closeView} onViewVerb={this.gotoVerb}/> : null  }    
                { this.state.currentView === 'Learn' ? <Learn verb={this.state.selectedVerb} profile={this.state.profile} lang={this.state.lang} conjugate={conjugate} onClose={this.closeView} /> : null  }    
                { this.state.currentView === 'Quizz' ? <Quizz level={this.state.quizzCurrentLevel} profile={this.state.profile} conjugate={conjugate} onUpdateProfile={this.updateProfile} onClose={this.closeView} onViewVerb={this.gotoVerb} onStartLevel={this.startQuizz} /> : null  }  
                { this.state.currentView === 'User' ? <User onClose={this.closeView} onViewVerb={this.gotoVerb} profile={this.state.profile} onUpdateProfile={this.updateProfile} /> : null  }  
            </div>
        );
    }
}

