import React, { Component }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faSortAlphaDown } from '@fortawesome/free-solid-svg-icons'
import { faSortAlphaUp } from '@fortawesome/free-solid-svg-icons'
import { faList } from '@fortawesome/free-solid-svg-icons'


export class Verbs extends Component {
    constructor(props) {
        super(props);
        //console.log('admin props', );


        this.state = {
            isOpen: true,
            view : 0
        };

        
     
    }
    componentDidMount() {
        console.log("VERB mounted");
    }

    setView(n) {
        this.setState({
            view: n
        });
    }

    formatVerb(verb){

        return (
            <div key={verb.es} onClick={() => this.props.onViewVerb(verb.es, 'Verbs')} style={{padding:"4px",marginLeft:"10%", height:"36px", marginRight:"10%", borderTop: "1px solid #a0a0a0", clear:"both"}} >
                <div style={{ fontSize:"150%", textAlign:"left", width:"40%" , float:"left"}}  >{verb.es}</div>
                <div style={{ paddingTop:"8px", fontSize:"100%", textAlign:"left" }}   >{verb[this.props.profile.lang]}</div>
            </div>
        
        );

    }

    getVerbList(infinitives, ascending){
        var order = ascending===0 ? -1 : 1;
        infinitives.sort((a, b) => (a.es < b.es  ? order :  order *-1 ))
        var v = infinitives.map(i => this.formatVerb(i));
        return v;
    }


    getVerbsGrouped(infinitives,verbEnding){
        //var sortedInfinitives1 = infinitives.filter(i => i.es.lastIndexOf(verbEnding) === i.es.length-2).sort((a, b) => (a.es < b.es  ? -1 :  1 ));
        var v = infinitives.filter(i => i.es.lastIndexOf(verbEnding) === i.es.length-2).sort((a, b) => (a.es < b.es  ? -1 :  1 )).map(i => ( <div key={i.es} style={{fontSize:"150%"}} onClick={() => this.props.onViewVerb(i.es, 'Verbs')} >{i.es}</div>));
        return v;
    }



    render() {
        

        let infinitives = this.props.verbs.map(function (verb) { return verb.infinitive });

        
        const close = <FontAwesomeIcon icon={faTimes} />  
        const sortUp = <FontAwesomeIcon icon={faSortAlphaUp} />  
        const sortDown = <FontAwesomeIcon icon={faSortAlphaDown} />  
        const list = <FontAwesomeIcon icon={faList} />
        



        return (

            <div style={{backgroundColor:"#dad2d8"}}>
                <div onClick={() => this.props.onClose()} style={{zIndex:"1000", opacity:"50%", fontSize:"200%", position:"sticky",top : "0px" , textAlign:"right" , paddingRight:"12px"}}>{close}</div>

                <h1 style={{fontFamily:"Lalezar", letterSpacing:"2px" , color: "#0f8b8d"}}>{this.props.profile.lang === 'en' ? "Verbs" : 'Verb'}</h1>
                <br/>
                <span style={{padding:"8px", border:this.state.view===0 ? "1px solid #000" : "1px solid #ccc"}} onClick={() => this.setView(0)}>{sortUp}</span>
                &nbsp;
                <span style={{padding:"8px", border:this.state.view===1 ? "1px solid #000" : "1px solid #ccc" }} onClick={() => this.setView(1)}>{sortDown}</span>
                &nbsp;
                <span style={{padding:"8px", border:this.state.view===2 ? "1px solid #000" : "1px solid #ccc"}} onClick={() => this.setView(2)}>{list}</span>
                

                <br/>

                {this.state.view === 2
                    ?
                    <div>
                        <br/>
                        <br/>
                        <b>ar</b>
                        {this.getVerbsGrouped(infinitives,"ar")}
                        <br/>
                        <b>ar</b>
                        {this.getVerbsGrouped(infinitives,"er")}
                        <br/>
                        <b>ar</b>
                        {this.getVerbsGrouped(infinitives,"ir")}
                        <br/>

                    </div>
                    
                    :

                    <div>
                        <br/>
                        <br/>
                        {this.getVerbList(infinitives,this.state.view)}
                    </div>
                    

                }
                
                <br/>
                
                <br/>

            </div>    

        );
    }
}

