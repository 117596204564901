import React, { Component }  from 'react';
import Swiper from 'react-id-swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'


export class Learn extends Component {
    constructor(props) {
        super(props);
        //console.log('admin props', );


        this.state = {
            user: props.color,
            currentSlide: 0,
            isPlaying: false
        };

        this.playEnded = this.playEnded.bind(this);
    }

    componentDidMount() {
        console.log("LEARN preload");

        for (let i = 0; i < 19; i++) {
            const url = "https://spansk.net/audio/verbs/" + this.props.verb + "/" + (i===18?"i":i) + ".mp3";
            var sound = document.createElement("audio");
            sound.autoPlay = false;
            sound.src = url;
            document.body.appendChild(sound);
          }

    }

    goto(n){
        this.setState({
            currentSlide: n
        });
    }

    



    getTense(n,verb){



        return (
            
                <div key={n}>
                    <div style={{margin:"16px", backgroundColor:"#049C9C" , height:"300px", borderRadius:"16px"}}>
                    

                        <table cellSpacing="0" style={{width:"100%"}}>
                            <thead>
                                <tr>
                                    <th colSpan="2" style={{width:"50%"}}>{this.props.profile.lang === 'no'?'Entall':'Singular'}</th>
                                    <th colSpan="2" style={{width:"50%"}}>{this.props.profile.lang === 'no'?'Flertall':'Plural'}</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{height:"50px"}}>yo</td>
                                    <td><span onClick={() => this.play(verb.infinitive.es,0 + (6*n))}>{verb.conjugations[n].es[0]}</span></td>
                                    <td style={{borderLeft:"2px solid #222"}} >nosotros<br/>nosotras</td>
                                    <td><span onClick={() => this.play(verb.infinitive.es,3 + (6*n))}>{verb.conjugations[n].es[3]}</span></td>
                                </tr>
                                <tr>
                                    <td style={{height:"50px"}}>tú</td>
                                    <td><span onClick={() => this.play(verb.infinitive.es,1 + (6*n))}>{verb.conjugations[n].es[1]}</span></td>
                                    <td style={{borderLeft:"2px solid #222"}}>vosotros<br/>vosotras</td>
                                    <td><span onClick={() => this.play(verb.infinitive.es,4 + (6*n))}>{verb.conjugations[n].es[4]}</span></td>
                                </tr>
                                <tr>
                                    <td style={{height:"50px"}}>él<br/>ella<br/>us</td>
                                    <td><span onClick={() => this.play(verb.infinitive.es,2 + (6*n))}>{verb.conjugations[n].es[2]}</span></td>
                                    <td style={{borderLeft:"2px solid #222"}}>ellos<br/>ellas<br/>uds</td>
                                    <td><span onClick={() => this.play(verb.infinitive.es,5 + (6*n)) }>{verb.conjugations[n].es[5]}</span></td>
                                </tr>
                            </tbody>
                        </table>


                    
                    </div>
                </div>
            
            
        )
    }


    afterSwipe(n){
        console.log("afterSwipe" , n);
        this.setState({
            currentSlide: n
        });

    }


    play(verb,num){
        if(num===null)
            num = 'i';
        let audio = new Audio("https://spansk.net/audio/verbs/" + verb + "/" + num + ".mp3")
        audio.onended =  this.playEnded;

        audio.play();
        this.setState({
            isPlaying: true
        });

    }

    playEnded(){
        this.setState({
            isPlaying: false
        });

    }

    
    render() {
// window.matchMedia('(max-width: 640px)').matches
        console.log("isPlaying" , this.state.isPlaying);
        const element = <FontAwesomeIcon icon={faVolumeUp} />        
        const close = <FontAwesomeIcon icon={faTimes} />  

        var _self = this;

        // Find the verb
        const verb = this.props.conjugate.verbs.find(x => x.infinitive.es === this.props.verb)

        const tenses = [];
        tenses.push(this.getTense(0,verb));
        tenses.push(this.getTense(1,verb));
        tenses.push(this.getTense(2,verb));

        console.log("RENDER const verb",verb);


        const params = {
            pagination: {
              el: '.swiper-pagination',
              clickable: true
            },
            on: {
                'slideChange': (swiper) => {_self.afterSwipe(swiper.realIndex)}
            }
          }


          // init 
        return (

            <div>
                <div onClick={() => this.props.onClose()} style={{zIndex:"1000", opacity:"50%", fontSize:"200%", position:"sticky",top : "0px" , textAlign:"right" , paddingRight:"12px"}}>{close}</div>

            <h1 style={{color:"white", marginBottom: "0px"}} >{verb.infinitive.es} 
                <span onClick={() => this.play(verb.infinitive.es,null)} style={{marginLeft:"8px", backgroundColor : this.state.isPlaying ? "#ccc" : "#a8201a", color: this.state.isPlaying ? "#a8201a" :"#95c9ff", fontSize:"75%", padding:"6px"}}>{element}</span></h1>
                <span style={{color:"#ccc"}}>{verb.infinitive[this.props.profile.lang]}</span>
            <br/>
            <br/>

            <div style={{clear:"both"}}>
                <div style={{borderBottom: this.state.currentSlide === 0 ? "3px solid #049C9C" : "none", color:"white",  paddingBottom:"4px", width:"20%", marginLeft:"20%",float:"left"}} onClick={() => this.afterSwipe(0)} >Infinitive</div>
                <div style={{borderBottom: this.state.currentSlide === 1 ? "3px solid #049C9C" : "none", color:"white",  paddingBottom:"4px", width:"20%", float:"left"}} onClick={() => this.afterSwipe(1)}>Preterite</div>
                <div style={{borderBottom: this.state.currentSlide === 2 ? "3px solid #049C9C" : "none", color:"white",  paddingBottom:"4px", width:"20%", float:"left"}} onClick={() => this.afterSwipe(2)}>Future</div>
            </div>

            <br style={{clear:"both"}}/>

            


                <Swiper activeSlideKey={this.state.currentSlide + ""} {...params}>
                    {tenses}
                </Swiper>

                <br/>
                <br/>
                

            </div>    

        );
    }
}

