import React, { Component }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'


const verbsPrlevel = 4;
const totalNumQuestions = 10;

export class Quizz extends Component {
    constructor(props) {
        super(props);
        //console.log('admin props', );


        this.state = {
            currentQuestion: null,
            currentQnA : null,
            transitionNumber: null,
            checkingAnswer: null,
            correct: false,
            slideLeft: false,
            leftPos : "0%",
            display : "block",
            numbersCorrect : 0,
            showComplete : false
        };

        
        this.checkAnswerComplete = this.checkAnswerComplete.bind(this);
        

       

    }

    


    nextQuestion(){
        if(this.props.level>0){
            alert("only one level completed...");
            return;
        }


        if(this.state.currentQuestion === totalNumQuestions){
            let audio = new Audio("https://spansk.net/audio/level-complete.mp3");  audio.play();

            let updatedProfile = this.props.profile;
            const thisLevel = {
                level : this.props.level,
                score : this.state.numbersCorrect
            };

            if(updatedProfile.levels === null){
                // add first completed level
                updatedProfile.levels = [thisLevel];
            }
            else{
                var prevScore = updatedProfile.levels.find(l => l.level === this.props.level);
                if(prevScore){
                    // update existing
                    console.log("update existing");
                    prevScore.score = thisLevel.score;
                }
                else{
                    // add new level
                    console.log("add new level");
                    updatedProfile.levels.push(thisLevel);
                }                
            }
            this.props.onUpdateProfile(updatedProfile);
            this.setState({
                showComplete : true
            });
            return;
        }
        
        let currentQuestion = (this.state.currentQuestion === null ? 1 : this.state.currentQuestion + 1);
        console.log("currentQuestion",currentQuestion) ;

        const currentQnA = this.generateQandA();
        console.log("currentQnA",currentQnA);
        this.setState({
            currentQnA: currentQnA,
            currentQuestion: currentQuestion,
            slideLeft: false,
            leftPos : "0%",
            display : "block",
            checkingAnswer : null
        });

        setTimeout(() => this.setTransitionNumber(), 300); 
    }


    generateQandA(){
        
        // get random verb
        var r = Math.floor(Math.random() * verbsPrlevel) + (this.props.level * verbsPrlevel );
        
        var o = this.props.conjugate.verbs[r].objects;
        var c = this.props.conjugate.verbs[r].conjugations;

        var tense = 0; // infinitive
        var sentence = "";
        var sentence_no = "";

        var q = [];
        var a = [];
        // find all combinations
        for (var i = 0; i < o.es.length; i++) {
            sentence = "";
            sentence_no = "";
            // loop all subjects
            for (var s = 0; s < this.props.conjugate.subjects.es.length; s++) {
                for (var s2 = 0; s2 < this.props.conjugate.subjects.es[s].pronouns.length; s2++) {
                    if(this.props.conjugate.subjects.es[s].optional){
                        sentence = "(" + this.props.conjugate.subjects.es[s].pronouns[s2] + ")";
                    }
                    else{
                        sentence = this.props.conjugate.subjects.es[s].pronouns[s2];
                    }
                    
                    sentence_no = this.props.conjugate.subjects.no[s].pronouns[s2];
        
                    // get verb
                    sentence = sentence + " " + c[tense].es[s];
                    sentence_no = sentence_no + " " + c[tense].no[s];
                    
                    var pronouns = o.es[i];
                    
                    for (var p = 0; p < pronouns.length; p++) {
                        if(pronouns[p] !== ""){
                            //console.log(sentence + " " + pronouns[p] + "     " + sentence_no + " " + o.no[i][p]);
                            q.push(sentence + " " + pronouns[p]);                            
                            a.push(sentence_no + " " + o.no[i][p]);
                        }
                    }
                }
            }
        }
        
        const randomQuestion = Math.floor(Math.random() * q.length);
        const randomAnswer = Math.floor(Math.random() * 4); // 0 til 3
        
        var QnA = {
            question : q[randomQuestion],
            answers : [null,null,null,null],
            correctAnsverIndex : randomAnswer
        };
        QnA.answers[randomAnswer] = a[randomQuestion];

        for (var j = 0; j < 4; j++) {
            if(QnA.answers[j] === null){
                const randomWrongAnswer = Math.floor(Math.random() * a.length);
                QnA.answers[j] = a[randomWrongAnswer];

            }
        }

        return QnA;


    }

    setTransitionNumber(){
        let transitionNumber = this.state.transitionNumber === null ? 2 : this.state.transitionNumber +1 ;
        if(this.state.currentQuestion === totalNumQuestions){
            transitionNumber = null;
        }

        this.setState({
            transitionNumber: transitionNumber
        });
    }

    getVebList(fromIndex){
        var links = [];
        let verbs = this.props.conjugate.verbs;
        for (let i = fromIndex; i < fromIndex +verbsPrlevel; i++) {
            links.push(<p key={i} onClick={() => this.props.onViewVerb(verbs[i].infinitive.es, 'Quizz')} style={{textDecoration : "underline"}} >{verbs[i].infinitive.es}</p>);
        }
        return (
            links
        );
    }


    closeView(){
        if(this.state.currentQuestion !== null && this.state.showComplete === false){
            const ret = window.confirm("Are you sure you want to cancel?");
            if(ret === false)
                return;
        }
        this.props.onClose();
    }


    checkAnswer(a){
        if(this.state.checkingAnswer !== null) // Prevent second answer
            return;

        const correct = ((a-1) === this.state.currentQnA.correctAnsverIndex?true:false);

        if(correct){
            let audio = new Audio("https://spansk.net/audio/correct.mp3");  audio.play();
        }
        else{
            let audio = new Audio("https://spansk.net/audio/wrong.mp3");  audio.play();            
        }

        this.setState({
            checkingAnswer: a,
            correct: correct,
            numbersCorrect: this.state.numbersCorrect + (correct ? 1 : 0)
        });
    
        setTimeout(() => this.checkAnswerComplete(), 1000); 

    }

    checkAnswerComplete(){
        this.setState({
            slideLeft: true,
            leftPos : "-100%"
            
        });
        setTimeout(() => this.prepareNextanswer(), 300); 

    }

    prepareNextanswer(){
        console.log("prepareNextanswer")
        this.setState({
            leftPos : "100%",
            display : "none"
        });
        setTimeout(() => this.prepareNextanswerComplete(), 50); 
    }

    prepareNextanswerComplete(){
        console.log("prepareNextanswerComplete")
        this.setState({
            display : "block"
        });
        setTimeout(() => this.nextQuestion(), 50); 
    }



    retakeLevel(){
        this.setState({
            currentQuestion: null,
            transitionNumber: null,
            checkingAnswer: null,
            correct: false,
            slideLeft: false,
            leftPos : "0%",
            display : "block",
            numbersCorrect : 0,
            showComplete : false
        });
        this.props.onStartLevel(this.props.level);

    }

    gotoNextLevel(){


        this.setState({
            currentQuestion: null,
            transitionNumber: null,
            checkingAnswer: null,
            correct: false,
            slideLeft: false,
            leftPos : "0%",
            display : "block",
            numbersCorrect : 0,
            showComplete : false
        });
        this.props.onStartLevel(null);

    }

    render() {
        
        const fromVerbIndex = (this.props.level * verbsPrlevel );
        //console.log("fromVerbIndex" , fromVerbIndex)

        const verbLinks =  this.getVebList(fromVerbIndex);

        //console.log("conjugate",this.props.conjugate);

        const close = <FontAwesomeIcon icon={faTimes} />  

        const redoIcon = <FontAwesomeIcon icon={faRedoAlt} />  

        const nextIcon = <FontAwesomeIcon icon={faArrowAltCircleRight} />  

        
        

        
        return (

            <div style={{backgroundColor:"#dad2d8" , position: "fixed" , top: "0px" , right: "0px" , left: "0px", bottom: "0px"  }}>

                {this.state.transitionNumber !== null 
                    ?
                    <div style={{position:"absolute", left:"20%", width:"60%", top:"90px", color:"#0f8b8d", textAlign:"center", fontFamily:"Lalezar", fontSize: "180px"}}>
                    {this.state.transitionNumber}
                    </div>
                    :
                    null
                    }
                        

                <div onClick={() => this.closeView()} style={{zIndex:"1000", opacity:"50%", fontSize:"200%", position:"sticky",top : "0px" , textAlign:"right" , paddingRight:"12px"}}>{close}</div>

                {this.state.currentQuestion === null
                ?
                <div>
                    <h1 style={{fontFamily:"Lalezar", letterSpacing:"2px", color:"#0f8b8d" }}>{this.props.profile.lang === 'en' ? "Level" : "Nivå"} {this.props.level + 1} </h1>
                    <br/>
                    <br/>
                        <div onClick={() => this.nextQuestion()} style={{border: "2px solid ##146A3A" ,backgroundColor: "#07a0c3", color:"white", fontWeight: "bold", marginLeft: "20%", width:"60%", borderRadius: "8px", fontSize: "120%", padding:"8px" }} >
                            Start
                        </div>
                        
                        <br/>







                    <br/>{this.props.profile.lang === 'en' ?"Verbs in this level:" :  "Verb på dette nivået"}
                    <br/>
                    
                    {verbLinks}
                    <br/>
                </div>
                :
                    this.state.showComplete 
                    ?
                    <div>
                        <h1 style={{fontFamily:"Lalezar", letterSpacing:"2px" , color: "#0f8b8d"}}>
                            {this.props.profile.lang === 'en' ? 
                            "LEVEL " + (this.props.level + 1) + " COMPLETED" :
                            "NIVÅ " + (this.props.level + 1) + " FULLFØRT"}
                        </h1>
                        
                        (Correct answers: {this.state.numbersCorrect})
                        <br/>
                        <br/>



                        <div onClick={() => this.gotoNextLevel()} style={{border: "2px solid ##146A3A" ,backgroundColor: "#07a0c3", color:"white", fontWeight: "bold", marginLeft: "20%", width:"60%", borderRadius: "8px", fontSize: "120%", padding:"8px" }} >
                            {nextIcon} &nbsp;Next level
                        </div>
                        <br/>
                        <div onClick={() => this.retakeLevel()} style={{border: "2px solid ##146A3A" ,backgroundColor: "#07a0c3", color:"white", fontWeight: "bold", marginLeft: "20%", width:"60%", borderRadius: "8px", fontSize: "120%", padding:"8px" }} >
                            {redoIcon} &nbsp;Repeat this level
                        </div>
                        <br/>
                        
                        
                    </div>
                    :


                <div style={{paddingTop: "16px"}}>
                    <div style={{width:"60%", marginLeft:"20%", marginBottom:"32px", height:"10px", padding:"3px",borderRadius:"8px",backgroundColor:"#999"}}>
                        <div style={{width: ((this.state.currentQuestion-1) * 10) + "%",  marginBottom:"32px", height:"10px", borderRadius:"5px",backgroundColor:"#ec9a29"}} />
                    </div>


                    <div style={{ backgroundColor:"#dad2d8", width:"100%",left: this.state.leftPos  , display: this.state.display, position : "absolute" ,  transition : "left 0.3s"}} >
                        <p style={{fontSize:"120%", fontWeight:"bold",}}>{this.state.currentQnA.question}</p>

                        <div onClick={() => this.checkAnswer(1)} style={{minHeight:"25px", fontSize:"120%", fontWeight:"bold", backgroundColor:"#fff", padding:"10px", lineHeight:"25px", textAlign:"center", borderRadius:"8px", margin: "10px", border: this.state.checkingAnswer === 1 ?  "4px solid " + (this.state.correct ? "green" : "red") : "4px solid #000"}}>
                            {this.state.currentQnA.answers[0]}
                        </div>
                        <div onClick={() => this.checkAnswer(2)} style={{minHeight:"25px", fontSize:"120%", fontWeight:"bold", backgroundColor:"#fff", padding:"10px", lineHeight:"25px", textAlign:"center", borderRadius:"8px", margin: "10px", border: this.state.checkingAnswer === 2 ? "4px solid " + (this.state.correct ? "green" : "red") : "4px solid #000"}}>
                            {this.state.currentQnA.answers[1]}
                        </div>
                        <div onClick={() => this.checkAnswer(3)} style={{minHeight:"25px", fontSize:"120%", fontWeight:"bold", backgroundColor:"#fff", padding:"10px", lineHeight:"25px", textAlign:"center", borderRadius:"8px", margin: "10px", border: this.state.checkingAnswer === 3 ? "4px solid " + (this.state.correct ? "green" : "red") : "4px solid #000"}}>
                            {this.state.currentQnA.answers[2]}
                        </div>
                        <div onClick={() => this.checkAnswer(4)} style={{minHeight:"25px", fontSize:"120%", fontWeight:"bold", backgroundColor:"#fff", padding:"10px", lineHeight:"25px", textAlign:"center", borderRadius:"8px", margin: "10px", border: this.state.checkingAnswer === 4 ? "4px solid " + (this.state.correct ? "green" : "red") : "4px solid #000"}}>
                            {this.state.currentQnA.answers[3]}
                        </div>
                        <br/>
                        <br/>(current question {this.state.currentQuestion})
                        <br/>(numbersCorrect: {this.state.numbersCorrect})

                    </div>

                </div>    
                
                }

            </div>    

        );
    }
}

;