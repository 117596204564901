import React, { Component }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'


export class User extends Component {
    constructor(props) {
        super(props);
        //console.log('admin props', );


        this.state = {
            isOpen: true,
        };
    }


    setLang(lang){
        this.props.onUpdateProfile(
            {
                ...this.props.profile,
                lang:lang
            }
        );
    }

    resetScores(){
        this.props.onUpdateProfile(
            {
                ...this.props.profile,
                levels:null
            }
        );        
    }

    renderResults(res, lang){
        return (
            res.map(l => 
                <tr key={l.level}>
                    <td>{lang === 'no' ? 'Nivå ' : 'Level'} {l.level + 1}</td>
                    <td>{l.score}</td>
                </tr>)
        );
        
    }


    render() {
        const close = <FontAwesomeIcon icon={faTimes} />  
        

        let results = this.props.profile.levels === null ? null : this.renderResults(this.props.profile.levels, this.props.profile.lang);

        return (

            <div style={{backgroundColor:"#dad2d8"}}>
                <div onClick={() => this.props.onClose()} style={{zIndex:"1000", opacity:"50%", fontSize:"200%", position:"sticky",top : "0px" , textAlign:"right" , paddingRight:"12px"}}>{close}</div>
                
                <h1 style={{fontFamily:"Lalezar", letterSpacing:"2px" , color:"#0f8b8d"}}>{this.props.profile.lang === 'en' ? "User profile" : 'Brukerprofil'}</h1>
                <b/>
                <br/>{this.props.profile.lang === 'en' ? "Select language:" : 'Velg språk:'}
                <br/>
                <br/>
                 
                <div onClick={() => this.setLang('no')} style={{ border: this.props.profile.lang === 'no' ? "3px solid #2ec4b6" : "3px solid #dad2d8" , borderRadius: "16px", marginLeft: "10%", width: "35%", float:"left"}}>
                    <img src="/img/no.svg" alt="Norsk" width="100" />
                </div>

                <div onClick={() => this.setLang('en')} style={{ border: this.props.profile.lang === 'en' ? "3px solid #2ec4b6" : "3px solid #dad2d8"  , borderRadius: "16px", marginLeft: "10%", width: "35%", float:"left"}}>
                <img src="/img/en.svg" alt="English" width="100" />
                </div>
                <br style={{clear: "both"}} />
                <br/>
                <br/>
                <br/>
                {
                    this.props.profile.levels !== null
                    ?
                    <div>
                    <b>{this.props.profile.lang === 'en' ? "My results" : 'Mine resultater'}</b>
                    <table>
                       <tbody>
                           {results}
                       </tbody>
                    </table>
                    <br/>
                    <br/>
                    <div onClick={() => this.resetScores()} style={{backgroundColor: "#07a0c3", color:"white", fontWeight: "bold", marginLeft: "20%", width:"60%", borderRadius: "8px", fontSize: "120%", padding:"8px" }} >
                            {this.props.profile.lang === 'en' ? 'Reset scores' : 'Nulstill resultatene'}
                    </div>
                    </div>
                    :
                    null
                }

                <br/>&copy; 2020 TW                
                <br/>
                     

                

                

                
                <br/>

            </div>    

        );
    }
}

